import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Switch, Route, Redirect } from 'react-router';

import { withRouter } from 'react-router-dom';

import Poll from './Poll';
import NotFound from './NotFound';

class Router extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/a/:activityId" component={Poll} />
        <Route exact path="/:accountId/:pollId" component={Poll} />
        <Route exact path="/:accountId/:pollId/e/:email" component={Poll} />
        <Route exact path="/preview/:accountId/:pollId" component={Poll} />

        <Route component={NotFound} />
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  return {
    data: state.data
  }
}

export default withRouter(connect(mapStateToProps)(Router));