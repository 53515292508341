import React from 'react';
import ReactDOM from 'react-dom';

import './scss/Index.scss';

import store from './store';
import history from './store/history';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import Router from './pages/Router';
import * as serviceWorker from './serviceWorker';

import ReactGA from 'react-ga'
ReactGA.initialize('UA-135456585-4');
ReactGA.pageview(window.location.pathname);

history.listen(location => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname);
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Router />
    </ConnectedRouter>
  </Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
