import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

import tinycolor from 'tinycolor2';

import { decode } from '../utils';

import $ from 'jquery';

import Promo from '../components/Promo';

class Poll extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false, color: '#153b89', backgroundColor: '#f5f7f9' };
  }

  componentDidMount() {
    // const src = '//cdn.zigpoll.com/static/js/main.js';
    const accountId = this.props.accountId; 
    const pollId = this.props.pollId;
    const activityId = this.props.activityId;

    window.Zigpoll = {
      accountId: accountId,
      pollId: pollId,
      activityId: activityId,
      onload: this.onload.bind(this),
    };

    const metadata = Object.fromEntries(new URLSearchParams(window.location.search));

    if (window.localStorage.getItem('zigpollEmail')) {
      metadata.email = window.localStorage.getItem('zigpollEmail');
    }

    Object.keys(metadata).forEach((key) => { 
      if (metadata[key] === undefined || metadata[key] === '') {
        delete metadata[key] 
      }
    });
    delete metadata['a'];
    delete metadata['aa'];
    delete metadata['r'];
    delete metadata['as'];
    delete metadata['ds'];

    window.Zigpoll.metadata = metadata;

    /* Flush query params*/
    // window.history.replaceState(null, '', window.location.pathname);

    var user = { metadata: {} };
    if (activityId) {
      user.id = activityId;
    }
    if (metadata.email) {
      user.metadata.email = metadata.email;
      user.id = metadata.email;
    }
    if (metadata.name) {
      user.handle = metadata.name;
      user.metadata.name = metadata.name;
    }
    if (metadata.shopify_customer_id) {
      user.metadata.shopify_customer_id = metadata.shopify_customer_id;
      user.id = metadata.shopify_customer_id;
    }
    window.Zigpoll.user = user;

    // var script = document.createElement("script");
    // script.id = 'zigpoll-script';
    // script.type = "text/javascript";
    // script.charset = "utf-8";
    // script.src = src;

    // document.head.appendChild(script);
    window._initZigpoll();
  }

  onload(error) {
    if (error) {
      this.setState({ notFound: true }, () => {
        this.setState({ loaded: true });
      });

      document.body.className = "loaded";

      return;
    }

    let backgroundColor = '#f5f7f9';
    let color = window.Zigpoll.displaySettings.backgroundColor;
    const maxWidth = window.Zigpoll.displaySettings.maxWidth;
    const title = window.Zigpoll.activePoll.title;

    let desc = null;
    if (window.Zigpoll.activePoll.firstSlide) {
      desc = window.Zigpoll.activePoll.firstSlide.title;
    }

    let type = null;
    let logo = null;
    let useCustomCSS = null;
    let customCSS = null;
    let pageDisplaySettings = window.Zigpoll.pageDisplaySettings;
    let backgroundImage = null;
    if (pageDisplaySettings) {
      type = pageDisplaySettings.style;
      color = pageDisplaySettings.foregroundColor;
      backgroundColor = pageDisplaySettings.backgroundColor;
      logo = pageDisplaySettings.logoImage;
      useCustomCSS = pageDisplaySettings.useCustomCSS;
      customCSS = pageDisplaySettings.customCSS;
      backgroundImage = pageDisplaySettings.backgroundImage;
    }

    // const $firstSlide = $("#zigpoll-poll-frame iframe").contents().find("#frame .slide").first();
    // const hasFooter = $("#zigpoll-poll-frame iframe").contents().find(".frame-content > .footer")[0];

    // if (!hasFooter) {
      $("#header").hide();
    // }

    this.setState({
      color,
      backgroundColor,
      type,
      logo,
      useCustomCSS,
      customCSS,
      backgroundImage
    }, () => {
      setTimeout(() => { 
        window.Zigpoll.open();

        this.setState({ loaded: true }, () => {
          document.body.className = "loaded";

          if (title) {
            let pageTitle = `Zigpoll : ${title}`;
            document.title = pageTitle;
            document.querySelector('meta[property="og:title"]').setAttribute("content", pageTitle);
            document.querySelector('meta[name="twitter:title"]').setAttribute("content", pageTitle);
          }

          if (desc) {
            document.querySelector('meta[name="description"]').setAttribute("content", desc);
            document.querySelector('meta[name="twitter:description"]').setAttribute("content", desc);
            document.querySelector('meta[property="og:description"]').setAttribute("content", desc);
          }

          setTimeout(() => {
            const translations = window.Zigpoll.translations || {};
            const thankYouText = translations['thanks'] || '';
            const $thankYou = $('<div id="close-text" class="'+(type ? type : 'wave')+'">'+thankYouText+'</div>');
            $thankYou.css('color', color);
            $thankYou.css('maxWidth', maxWidth);

            $('body').append($thankYou);

            window.Zigpoll.onClose = () => {
              $thankYou.addClass('active');
              document.body.className = "loaded closed";
            }

            if (this.props.isPreview) {
              const languages = window.Zigpoll.languages || [];
              if (languages.length > 1) {
                const $languageSelector = $('<div id="language-selector"><select></select></div>');
                const urlParams = new URLSearchParams(window.location.search);
                const selectedLang = urlParams.get('lang');

                languages.forEach((lang) => {
                  $languageSelector.find('select').append(`<option value="${lang}" ${selectedLang === lang ? 'selected' : ''}>${lang}</option>`);
                })
                $languageSelector.on('change', (e) => {
                  const urlParams = new URLSearchParams(window.location.search);
                  urlParams.set('lang', e.target.value);
                  window.location.search = urlParams;
                });
                $('body').append($languageSelector);
              }
            }
          }, 500);

          setTimeout(() => {
            /* Add white if theres a banner that matches the BG exactly */
            const $rewardBanner = $("#zigpoll-poll-frame iframe").contents().find("#reward-banner");
            if ($rewardBanner[0]) {
              let bg = type === 'simple' ? backgroundColor : color;
              bg = bg.slice(1);
              const bannerBg = tinycolor($rewardBanner.css('background-color')).toHex();
              if (bannerBg.toLowerCase() === bg.toLowerCase()) {
                $("#zigpoll-poll-frame").css('box-shadow', '0 0 0 1px rgba(255,255,255,.15)');
              }
            }
          }, 500);
        });
      }, 100);
    });
  }

  componentWillUnmount() {
    const script = document.getElementById("zigpoll-script");
    script.parentNode.removeChild(script);
    this.setState({ loaded: false, color: '#153b89', backgroundColor: '#f5f7f9' })
    document.body.className = "";
  }

  render() {
    let style = {};
    if (this.state.color) {
          // background: linear-gradient(150deg, darken($purple, 15) 15%, darken(lighten($purple, 20), 5) 70%, lighten(lighten($purple, 20), 20) 94%);

      // const a = tinycolor(this.state.color).darken(12).darken(5);
      // const b = tinycolor(this.state.color).darken(7);
      // const c = tinycolor(this.state.color).darken(7);
      style = { background: this.state.color };

      if (this.state.loaded) {
        const metaThemeColor = document.querySelector("meta[name=theme-color]");
        metaThemeColor.setAttribute("content", this.state.type === 'simple' ? this.state.backgroundColor : this.state.color);
      }
    }

    let notFound = null;
    if (this.state.notFound) {
      const metaThemeColor = document.querySelector("meta[name=theme-color]");
      metaThemeColor.setAttribute("content", '#153b89');

      notFound = <div id="not-found">
        <div className="top">404</div>
        <div className="title">Survey Not Found</div>
        <div className="copy">Please try again.</div>
      </div>
    }

    return (<div id="poll" className={`poll ${this.state.loaded ? 'loaded' : ''}`} style={{ backgroundColor: this.state.backgroundColor, backgroundImage: this.state.backgroundImage ? `url(${this.state.backgroundImage})` : '' }}>
      { this.state.type !== 'simple' && <div className={`bg ${this.state.loaded ? 'loaded' : ''}`} style={style} /> }

      <Promo />

      <div id="header">
        <div className="wrapper">
          <div className="logo"><a href="https://www.zigpoll.com" target='_blank'>Zigpoll</a></div>
        </div>
      </div>

      { this.state.logo && <div id="custom-logo"><img src={this.state.logo} /></div> }

      { this.state.type !== 'simple' && <div className="wave-divider inverted" aria-hidden="true"><svg class="wave-1hkxOo" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100" preserveAspectRatio="none"><path class="wavePath-haxJK1" d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z" fill={this.state.color}></path></svg></div> }

      { notFound }

      { this.state.useCustomCSS && <style>{ this.state.customCSS }</style>}
    </div>);
  }
}

function mapStateToProps(state, ownProps) {
  let { accountId, pollId, activityId, email } = ownProps.match.params;
  let isPreview = false;

  if (window.location.href.indexOf('app.zigpoll.com/preview') === -1) {
    accountId = decode(accountId);
    pollId = decode(pollId);
  } else {
    isPreview = true;
  }

  /* Hack in case query params are unavailable (shopify email?) */
  if (email) {
    window.localStorage.setItem('zigpollEmail', email);
    const pathname = ownProps.location.pathname.split('/e/')[0];
    ownProps.history.replace({ pathname });
  }

  activityId = decode(activityId);

  return {
    accountId,
    pollId,
    activityId,
    isPreview
  }
}

export default connect(mapStateToProps)(Poll);