const bs58 = require('bs58');

export function encode(str) {
  try {
    return bs58.encode(Buffer.from(str, 'hex'));
  } catch(err) {
    return str;
  }
};

export function decode(str) {
  try {
    return bs58.decode(str).toString('hex');
  } catch(err) {
    return str;
  }
}
