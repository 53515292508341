import React, { Component } from 'react';
import { connect } from 'react-redux';
import Promo from '../components/Promo';

class Poll extends Component {
  render() {
    document.body.className = "loaded";

    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute("content", '#0e285d');

    const notFound = (<div id="not-found">
      <div className="top">404</div>
      <div className="title">Survey Not Found</div>
      <div className="copy">Please try again later.</div>
    </div>);

    return (<div id="poll" className={`poll loaded`}>
      <div className={`bg loaded`} style={{}} />
      <Promo />
      <div id="header">
        <div className="wrapper">
          <div className="logo"><a href="https://www.zigpoll.com" target='_blank'>Zigpoll</a></div>
        </div>
      </div>
      
      <div className="wave-divider inverted" aria-hidden="true"><svg class="wave-1hkxOo" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100" preserveAspectRatio="none"><path class="wavePath-haxJK1" d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z" fill="#0e285d"></path></svg></div>

      { notFound }
    </div>);
  }
}

function mapStateToProps(state, ownProps) {
  return {
    data: state.data,
  }
}

export default connect(mapStateToProps)(Poll);