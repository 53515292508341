import React, { Component } from 'react';

export default class Promo extends Component {
  render() {
    return null;

    // return (<div id="promo" className={`poll product-hunt`}>
    //   <p>🎉 We're on Product Hunt! <a href="https://www.producthunt.com/posts/zigpoll" target="_blank" rel="noopener noreferrer" >Check it out</a>.</p>
    // </div>);
  }
}
